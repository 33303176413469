import {
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CSmartTable,
} from '@coreui/react-pro'
import React, { useState } from 'react'
import { useEffect } from 'react'
import PlayerService from 'src/service/PlayerService'
const PlayerTeam = () => {
  const [loading, setLoading] = useState()
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(20)
  const [users, setUsers] = useState([])

  const columns = [
    {
      key: 'image',
      label: 'Image',
      filter: false,
      sorter: false,
    },
    {
      label: 'Player',
      key: 'full_name',
    },
    { label: 'Position', key: 'position_name' },
    { label: 'Value', key: 'svalue' },
    { label: 'Team Name', key: 'team_name' },
  ]
  const getUsers = useEffect(() => {
    setLoading(true)
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    PlayerService.getPlayers(offset, itemsPerPage, activePage, params)
      // .then((response) => response.json())
      .then((result) => {
        setUsers(result.data)
        setLoading(false)
      })
  }, [activePage, columnFilter, columnSorter, itemsPerPage])
  return (
    <>
      <CRow>
        <CAccordion activeItemKey={2}>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>
              {' '}
              <strong>Player Teams</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CSmartTable
                columns={columns}
                columnFilter={{
                  external: true,
                }}
                columnSorter={{
                  external: true,
                }}
                scopedColumns={{
                  image: (item) => (
                    <td>
                      {item?.is_avatar === 1 ? (
                        <img
                          src={`${process.env.REACT_APP_PLAYER_AVATAR_URL}${item.image}`}
                          alt="img"
                          width="50"
                          height="50"
                        />
                      ) : (
                        <img
                          src={`${process.env.REACT_APP_API_URL}uploads/player/${item.image}`}
                          width="50"
                          height="50"
                          alt="img"
                        />
                      )}
                    </td>
                  ),
                  team_name: (item) => <td>{item.team_name ? item.team_name : '-'}</td>,
                }}
                items={users?.data}
                itemsPerPage={itemsPerPage}
                itemsPerPageSelect
                loading={loading}
                pagination={{
                  external: true,
                }}
                paginationProps={{
                  activePage: activePage,
                  pages: Math.ceil(users?.total / itemsPerPage) || 1,
                }}
                tableProps={{
                  hover: true,
                  responsive: true,
                }}
                onActivePageChange={(activePage) => setActivePage(activePage)}
                onColumnFilterChange={(filter) => {
                  setActivePage(1)
                  setColumnFilter(filter)
                }}
                onItemsPerPageChange={(itemsPerPage) => {
                  setActivePage(1)
                  setItemsPerPage(itemsPerPage)
                }}
                onSorterChange={(sorter) => setColumnSorter(sorter)}
              />
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
    </>
  )
}

export default PlayerTeam
