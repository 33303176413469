import React, { useEffect, useState } from 'react'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCollapse,
  CSmartTable,
} from '@coreui/react-pro'
import ToastComponent from 'src/components/common/TaostComponent.js'
import PlayerService from 'src/service/PlayerService'
import CommonService from 'src/service/CommonService'
import UpdateImage from './UpdateImage'
const PlayerProfileListing = (props) => {
  const [loading, setLoading] = useState()
  const [visibleHorizontal, setVisibleHorizontal] = useState(false)
  const [selectedPlayerId, setSelectedPlayerId] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(20)
  const [users, setUsers] = useState(props.users)
  const [details, setDetails] = useState([])
  const [playerData, setPlayerData] = useState({})
  const columns = [
    {
      label: 'PID',
      key: 'id',
      _style: { width: '10%' },
    },
    {
      label: 'Image',
      key: 'image',
      filter: false,
    },
    {
      label: 'Player',
      key: 'full_name',
    },
    { label: 'Value', key: 'svalue' },
    { label: 'Position', filter: false, key: 'position_name' },
    { label: 'Team', key: 'team_name' },
    {
      key: 'show_details',
      label: 'Actions',
      filter: false,
      sorter: false,
    },
  ]
  const getBadge = (status) => {
    switch (status) {
      case 1:
        return 'success'
      case 0:
        return 'danger'
      default:
        return 'danger'
    }
  }
  const [selectedImage, setSelectedImage] = useState('')
  const [isAvatar, setIsAvatar] = useState()
  const toggleDetails = (index, item) => {
    setSelectedPlayerId(index)
    setPlayerData(item)
    setIsAvatar(item?.is_avatar)
    if (item?.is_avatar === 1) {
      const fileNameWithoutExtension = item?.image.split('.').slice(0, -1).join('.')
      setSelectedImage(fileNameWithoutExtension)
    }
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      // newDetails = [index]
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }

  const getUsers = useEffect(() => {
    setLoading(true)
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    PlayerService.getPlayers(offset, itemsPerPage, activePage, params).then((result) => {
      setUsers(result.data)
      setLoading(false)
    })
  }, [activePage, columnFilter, columnSorter, itemsPerPage, props])
  return (
    <>
      <CSmartTable
        columns={columns}
        columnFilter={{
          external: true,
        }}
        columnSorter={{
          external: true,
        }}
        scopedColumns={{
          image: (item) => (
            <td>
              {item?.is_avatar === 1 ? (
                <img
                  src={`${process.env.REACT_APP_PLAYER_AVATAR_URL}${item.image}`}
                  alt="img"
                  width="50"
                  height="50"
                />
              ) : (
                <img
                  src={`${process.env.REACT_APP_API_URL}uploads/player/${item.image}`}
                  width="50"
                  height="50"
                  alt="img"
                />
              )}
            </td>
          ),
          svalue: (item) => <td>${item.svalue}m</td>,
          team_name: (item) => <td>{item.team_name ? item.team_name : '-'}</td>,
          is_active: (item) => (
            <td>
              <CBadge color={getBadge(item.is_active)}>
                {' '}
                {item.is_active === 1 ? 'Active' : 'In-Active'}
              </CBadge>
            </td>
          ),
          show_details: (item) => {
            return (
              <>
                <td className="py-2">
                  <CButton
                    size="sm"
                    color="success"
                    className="ml-1"
                    onClick={() => {
                      setVisibleHorizontal(!visibleHorizontal)
                      toggleDetails(item.id, item)
                    }}
                    aria-expanded={visibleHorizontal}
                    aria-controls="collapseEdit"
                  >
                    Manage Image
                  </CButton>
                </td>
              </>
            )
          },
          details: (item) => {
            return (
              <CCollapse visible={details.includes(item.id)}>
                <CCardBody>
                  <CCollapse id="collapseEdit" horizontal visible={visibleHorizontal}>
                    <CCard className="mb-4">
                      <CCardHeader>
                        <strong>Edit Image</strong>
                      </CCardHeader>
                      <CCardBody>
                        <UpdateImage
                          playerId={item.id}
                          playerData={playerData}
                          selectedPlayerId={selectedPlayerId}
                          selectedImage={selectedImage}
                          setSelectedImage={setSelectedImage}
                          isAvatar={isAvatar}
                          setIsAvatar={setIsAvatar}
                          setUsers={setUsers}
                        />
                      </CCardBody>
                    </CCard>
                  </CCollapse>
                </CCardBody>
              </CCollapse>
            )
          },
        }}
        items={users?.data}
        itemsPerPage={itemsPerPage}
        itemsPerPageSelect
        loading={loading}
        pagination={{
          external: true,
        }}
        paginationProps={{
          activePage: activePage,
          pages: Math.ceil(users?.total / itemsPerPage) || 1,
        }}
        tableProps={{
          hover: true,
          responsive: true,
        }}
        onActivePageChange={(activePage) => setActivePage(activePage)}
        onColumnFilterChange={(filter) => {
          setActivePage(1)
          setColumnFilter(filter)
        }}
        onItemsPerPageChange={(itemsPerPage) => {
          setActivePage(1)
          setItemsPerPage(itemsPerPage)
        }}
        onSorterChange={(sorter) => setColumnSorter(sorter)}
      />
    </>
  )
}

export default PlayerProfileListing
