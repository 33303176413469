import {
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PlayerService from 'src/service/PlayerService'
import ToastComponent from 'src/components/common/TaostComponent'
import Loader from 'src/components/Loader'
import TeamService from 'src/service/TeamService'
import Bootbox from 'bootbox-react'

const TeamSponsorLogo = (props) => {
  const [loading, setLoading] = useState(false)
  const [teamList, setTeamList] = useState(false)
  useEffect(() => {
    setLoading(true)
    TeamService.getTeamListByGrade()
      .then((res) => {
        if (res.status === 200) {
          setTeamList(res.data)
          setLoading(false)
        }
      })
      .catch((e) => {
        ToastComponent(e.response?.data?.message, 'error')
      })
  }, [])

  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    team: Yup.string().required('Team is required'),
  })

  const [showConfirm, setShowConfirm] = useState(false) // Bootbox visibility
  const [formData, setFormData] = useState(null)

  const formik = useFormik({
    initialValues: {
      team: null,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data) => {
      setFormData(data) // Temporarily store form data
      setShowConfirm(true) // Show Bootbox confirmation
    },
  })

  const handleConfirm = () => {
    setShowConfirm(false) // Hide confirmation box
    setLoader(true)
    // Proceed with API call
    PlayerService.updateTeamSponsorLogoOnPlayers(formData)
      .then((res) => {
        if (res.status === 200) {
          ToastComponent(res.message, 'success')
        } else {
          ToastComponent(res.message, 'error')
        }
        setLoader(false)
      })
      .catch((err) => {
        ToastComponent('Something went wrong', 'error')
        setLoader(false)
      })
  }

  const handleCancel = () => {
    setShowConfirm(false) // Close confirmation box without doing anything
  }

  return loading ? (
    <Loader />
  ) : (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={4}>
          <CFormSelect
            aria-label="Select Team"
            name="team"
            className={
              'form-control' + (formik.errors.team && formik.touched.team ? ' is-invalid' : '')
            }
            value={formik.values.team}
            onChange={formik.handleChange}
            id="team"
          >
            <option value={0}>Select Team</option>
            {teamList &&
              teamList.map((item, key) => (
                <option value={item?.id} key={key}>
                  {item?.name}
                </option>
              ))}
          </CFormSelect>
          {formik.errors.team && formik.touched.team && (
            <CFormFeedback invalid>{formik.errors.team}</CFormFeedback>
          )}
        </CCol>

        <CCol md={4}>
          <div className="form-group">
            {' '}
            <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
              Submit
            </CLoadingButton>
          </div>
        </CCol>
      </CForm>
      <Bootbox
        show={showConfirm}
        type={'confirm'}
        title="are you sure"
        message={'Are you sure you want to apply team sponsor logo to all associated players?'}
        onSuccess={handleConfirm}
        onCancel={handleCancel}
        onClose={handleCancel}
      />
    </>
  )
}

export default TeamSponsorLogo
